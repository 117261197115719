.outer-box {
box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
padding-top: 100px;
margin-left: 30px;
width: 840px;
height: 764px;
}

.header {
  font-weight: 600;
font-size: 14px;
line-height: 14px;
letter-spacing: 0.07em;
padding-top: 16px;
padding-left: 24px;
padding-bottom: 22px;
color: #3D3D3D;
display: flex;
justify-content: flex-start;
}

.line {
  background-color:  #EBEFF2;
  height: 0.5px;
  opacity: 0.2;
  margin-bottom: 24px;
  padding: 0;
  margin-top: 0;
}

.inner-box {
  height: 120px;
  width: 793px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.first-column {
  padding: 17px 0 31px 17px;
}

.first-column div {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.07em;
  color: #000000;
  min-width: 129px;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 6px;
}

 .second-column-left {
  padding: 15px 0 31px 22px;
  cursor: pointer;
}

.second-column{
  padding: 17px 0 31px 17px;
}

.second-column-left div {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.07em;
  color: #000000;
  min-width: 129px;
  justify-content: flex-start;
  display: flex;
margin-bottom: 6px;
border-radius: 0;
}

.second-column div {
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.07em;
  color: #000000;
  justify-content: flex-start;
  display: flex;
  border-radius: 0;
  min-width: 129px;
  margin-bottom: 6px;
}

.second-header {
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.07em;
  color: #3D3D3D;
  display: flex;
  justify-content: flex-start;
}

.textField {
  border: 0.3px solid #CCD0DB;
  box-sizing: border-box;
  width: 288px;
  height: 24px;
  margin-bottom: 12px;
  border-radius: 0;
  
}

.textField input {
  padding: 0;
  font-size: 10px;
  line-height: 14px;
display: flex;
padding: 3px 13px;
align-items: center;
letter-spacing: 0.07em;
color: #CCD0DB ;
border: 0.3px solid rgba(204,208,219, 0.4);
}

.textFieldNarrow {
  border: 0.3px solid #CCD0DB;
  box-sizing: border-box;
  width: 150px;
  height: 16px !important;
  margin-bottom: 12px;
  border-radius: 0;
}

.textFieldNarrow input {
  padding: 0;
  font-size: 10px;
  line-height: 14px;
display: flex;
padding: 1px 13px;
align-items: center;
letter-spacing: 0.07em;
color: #CCD0DB ;
border: 0.3px solid rgba(204,208,219, 0.4);
}

.forth-column {
  padding: 17px 0 31px 17px;
}

.forth-column-left {
  padding: 15px 0 31px 17px;
}

.forth-column div {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.07em;
  color: #000000;
  min-width: 129px;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 6px;
}

.forth-column-left div {
 border-radius: 0;
}

.week-days {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.week-button {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  border: 0.5px solid #CCD0DB;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.07em;
  color: #3D3D3D;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: 13px;
  height: 18px;
  padding-top: 2px;
}

.textFieldWrapper {
  margin-bottom: 8px;
  display: flex;
}

.date {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.07em;
  color: #000000;
  justify-content: flex-start;
  display: flex;
}

.selected {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  border: 0.5px solid #CCD0DB;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.07em;
  background-color: #3D3D3D;
  display: flex;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: 13px;
  height: 18px;
  padding-top: 2px;
}