.objectPage-wrapper {
  padding: 100px 30px 30px 30px;
  max-width: 1440px;
  margin: auto;
  min-height: 1000px;
  padding-bottom: 100px;
  overflow: hidden;
}

.Heading {
  font-weight: semibold;
  font-size: 18px;
  line-height: 22px;
  color: #3d3d3d;
}

.PageMobile {
  padding-left: 32px !important;
  padding-top: 20px;
}


@media (max-width: 992px) {
.Heading {
  font-weight: semibold;
  font-size: 24px;
  line-height: 29px;
  color: #3d3d3d;
  padding-left: 10px;
}
}

.tab-label-summary {
  font-size: 10px !important;
  font-weight: 800 !important;
  font-family: 'Montserrat', sans-serif !important;
  padding: 0 10px !important;
  min-height: 38px !important;
  background-color: #f6f6f4 !important;
}

.no-image-swiper {
  height: 514px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.no-image-swiper img {
  background-color: #3d3d3d;
  opacity: 0.5;
  padding: 5px 20px;
  border-radius: 20px;
  width: 300px;
  height: 80px;
  margin-bottom: 20px;
}

.tabs-wrap {
  padding-top: 25px;
  min-height: auto !important;
}