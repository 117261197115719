.Marker {
  background: url('../../../images/Marker.svg') no-repeat;
  z-index: 5000;
  padding: 22px 16px !important;
  margin-bottom: -5px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 10s;
  opacity: 1;
}

.marker-popup {
  position: relative;
    bottom: 10px;
    height: 30px;
    width: 250px;
    left: -100px;
    background-color: white;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    padding: '10px 14px 16px 14px';
    font-size: 14px;
    z-index: 500;
}

.buttonStyle {
  border: none;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: white;
};

.closeStyle {
  height: '13px';
};