.sort-menu {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.07em;
  color: #3D3D3D;
  padding-bottom: 30px;
  height: 15px;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px 12px 16px;
  cursor: pointer;
}

.label:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px 12px 16px;
  background-color: lightgray;
  border-radius: 30px;
}

.name-sort {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px 12px 0;
}