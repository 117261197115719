.ImageContainer {
  margin: 0 0 30px 0;
  overflow: hidden;
  border-radius: 0 !important;

  @media (max-width: 500px) {
    margin-bottom: 21.5px;
  }
}

.Image {
  position: relative !important;
  width: 100%;
  object-fit: cover;
  height: 560px;
  overflow: hidden !important;
  border-radius: 0 !important;
  user-select: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  @media (max-width: 1280px) {
    height: 460px;
  }

  @media (max-width: 1024px) {
    height: 380px;
  }

  @media (max-width: 992px) {
    padding: 0;
    height: auto;
  }
}

.Swiper {
  max-height: 560px;
  overflow: hidden;

  @media (max-width: 1280px) {
    height: 460px;
  }
  @media (max-width: 1024px) {
    height: 380px;
  }
}

.Thumb {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  height: 276px;
  user-select: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  @media (max-width: 1280px) {
    height: 225px;
  }
  @media (max-width: 1024px) {
    height: 185px;
  }
}