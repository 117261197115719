@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'react-toastify/dist/ReactToastify.css';


html {
	height: 100%; 
}

body {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100% !important; 
  height: 100%;
  overflow-y:scroll
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  height: 100%;
  min-height: 100% !important;
  padding: 0;
}

.swiper-button-next, .swiper-button-prev {
  color: #ffffff;
}

