.picTile {
  background-color: rgba(	251,251,251, 0.9);
  border: 1px solid #CCD0DB;
  font-family: 'Montserrat', sans-serif !important;
  height: 116px;
  width: 315px;
  display: flex;
  flex-direction: row;
  border-radius: 13px;
  align-items: center;
  margin-right: 39px;
  color: #3d3d3d !important;
  position: relative;
  cursor: pointer;
}

.picTile img {
  padding-left: 13px;
}

.textCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
}

.titleTile {
  margin: 0 0 8px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.textTile {
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
}

.fav-badge {
  background-color: rgba(61,61,61, 0.9);
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  width: 82px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 26px;

  border-top-right-radius: 13px;
  border-bottom-left-radius: 13px;
}

@media (max-width: 500px) {
  .picTile {
    margin-bottom: 15px;
    margin-right: 0;
    width: 100%;

  }
}