.MuiDataGrid-cell {
  white-space: normal !important;
}

.MuiTypography-root {
  font-size: 12px !important;
  font-family: 'Montserrat', sans-serif !important;
}

.MuiButton-root {
  font-family: 'Montserrat', sans-serif !important;
  color: #3d3d3d !important;
  border: 1px solid rgba(61, 61, 61, 0.3) !important;
}

.Container {
  margin-right: 0;
  padding-right: 0;
  border: transparent !important;
}

li {
  font-size: 10px;
}

ul {
  padding-left: 10px;
}

.link {
  text-decoration: underline;
  color: #3d3d3d;
  background-color: transparent;
  padding: 0;
  /* font-weight: 500; */
  margin: 0;
  border: none;
}

.link:hover {
  color: #979797;
  /* font-weight: 500; */
  text-decoration: underline;
}

.popover {
  padding: 10px;
}

.table-wrapper {
width: 100%;
overflow-x: scroll;
display: flex;
flex-grow: 3;
flex-shrink: 1;
flex-basis: auto;
flex: 1;
}

.total {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.07em;
  color: #3D3D3D;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 30px;
}

.no-rows-message {
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  color: red;
  font-weight: 600;
  padding-top: 10px;
}

.table-pics {
  width: 193px;
  overflow: hidden;
  height: 115px;
}

.table-pics img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
}

.fullscreen .table-pics {
  width: 80%;
  height: 80% !important;
  min-height: 500px;
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
  object-position: center center;
}

.fullscreen .table-pics img {
border: 4px solid transparent !important;
object-position: center center;
}

.table-slider {
  border: none !important;
}

.table-slider div div div button svg{
  height: 20px !important;
  width: 20px !important;
}

.table-header {
  flex: 1;
  overflow: hidden;
  background-color: #3d3d3d;
  color: #ffffff;
  height: 48px;
  font-size: 12px;
  display: flex;
  align-items:center;
  justify-content: space-between !important;
  font-family: 'Montserrat', sans-serif !important;
  text-transform: capitalize !important;
  margin-right: 0 !important;
  font-weight: 400;
  text-align: left;
  width: -webkit-fill-available;
}

.button-header-group{
  display: none;
}

.label-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  padding-left: 0 !important;
}

.label-arrow:hover div {
  display: flex;
  padding: 0;
  align-items: center;
}

.sorting-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sorting-menu button {
  color: #3d3d3d !important;
  font-size: 12px !important;
  padding: 8px 15px;
  cursor: pointer;
  text-align: left;
}

.sorting-menu button:hover {
  background-color: rgba(61,61,61,0.4);
}

.ReactVirtualized__Table__headerColumn {
  margin-right: 0 !important;
  margin-left: 0 !important;
 
}

.ReactVirtualized__Table__rowColumn {
  height: 100;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  white-space: unset;
  text-align: left;
}

.ReactVirtualized__Table__row {
border-bottom: 1px solid rgba(61, 61, 61, 0.1);
}

.ReactVirtualized__Table__row:hover {
 background-color: rgba(61, 61, 61, 0.2) !important;
 outline: 0 !important;
  }


.ReactVirtualized__Table__headerRow {
  width: 100%;
padding-right: 0 !important;
}

.title-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
}

.title-one-cell:hover > p {
  text-decoration: underline;
}


.attr-cell {
column-count: 2;
border-bottom: none !important;
}

.attr-item {
  min-height: 40px;
 display: flex;
 flex-direction: row;
}

.link-layer {
  position: absolute;
  z-index: 200;
  background: transparent;
  border: none;
  height: 115px;
  top: 72px;
  left: 25px;
  width: 140px;
  cursor: pointer;
}

.expand-button {
  margin-top: 10px !important;
  margin-left: -3px;
}

.collapse-cell {
  padding-left: 16px !important;
  display: flex !important;
  flex-direction: column !important;
}

.collapsed-item {
  font-size: 12px !important;
}

.grey-heart-offer {
  background-color: transparent;
  margin: 0 0 0 8px !important;
  margin: 0;
  height: 25px;
  width: 25px;
  border: none;
  background-image: url('../../../images/heartL.svg');
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.white-heart-offer {
  background-color: transparent;
  padding: 0px;
  margin: 0 0 0 8px;
  height: 25px;
  width: 25px;
  border: none;
  background-image: url('../../../images/heartW.svg');
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.transparent-button-round {
  background-color: transparent;
  border: none;
  padding: 2.7px 0;
  margin: 0 0 0 5px;
  display: flex;
  align-items: center;
}

.transparent-button-round:hover {
  border: none;
  border-radius: 100%;
  padding: 2.7px 0;
  margin: 0 0 0 5px;
  display: flex;
  align-items: center;
}

.desc-cell p {
  margin: 0 !important;
}


.desc-cell p:nth-child(2) {
  display: none !important;
}

.more-button {
  background-color: transparent;
  padding: 4px 12px;
  border: 0.5px solid #c4c4c4;
  border-radius: 8px;
  font-size: 12px;
  margin-top: 7px;
  width: auto;
  cursor: pointer;
  color: #3d3d3d;
  font-family: 'Montserrat', sans-serif !important;
}

.tableOffers {
  display: grid;
  grid-template-columns: 60px 140px 200px 226px 200px 400px 230px 300px  150px 150px 170px 120px 130px 150px 120px 120px 200px 200px 160px 160px 200px;
  justify-content: start;
  align-content: start;
  height: 100%;
}

.tableOffers:hover {
  background-color: rgba(61, 61, 61, 0.2) !important;
}

.tableHeader {
 display: grid;
 border-bottom: 1px solid #D9D9D9;
 height: 50px;
 background-color: #f9f9f9;
 color: #333333;
 justify-content: start;
 align-content: center;
 align-items: center;
 justify-items: start;
 grid-template-columns: 60px 140px 200px 226px 200px 400px 230px 300px  150px 150px 170px 120px 130px 150px 120px 120px 200px 200px 160px 160px 200px;
}

.tableHeader div {
  font-size: 12px !important;
  font-weight: 600;
  padding-left: 15px;
  text-align: left;
  color: #333333;
}

.common-cell {
  margin-left: 0 !important;
}

.heartContainer {
 padding-left: 25px !important;
}

@media (hover: hover) {
  .grey-heart-offer:hover {
    background-color: transparent;
    padding: 0;
    margin: 0 0 0 8px;
    height: 25px;
    width: 25px;
    border: none;
    background-image: url('../../../images/heartW.svg');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  .white-heart-offer:hover {
      background-color: transparent;
      padding: 0;
      margin: 0 0 0 8px;
      height: 25px;
      width: 25px;
      border: none;
      background-image: url('../../../images/heartL.svg');
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;
  }
  
}
