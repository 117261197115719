.appBarWhite {
  background-color: #ffffff !important;
  box-shadow: none !important;
  border-top: 1px solid rgba(74,74,86, 0.15);
}

.appBarWhite div {
  align-items: center !important;
  justify-content: space-evenly !important;
}

@media (min-width: 600px) {
  .appBarWhite {
  display: none !important;
  }
}