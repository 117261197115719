.listed {
  margin-top: 10px !important;
  background-color: #F6f6f4 !important;
  padding: 20px 10px 20px 20px !important;
}

.listed div:last-child div span:first-child {
  width: 270px;
}

.desc-and-listed {
  display: flex;
  flex-direction: row !important;
  margin-left: 32px;
  padding-right: 30px;
}

.desc-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  text-align: left;
  padding-top: 20px !important;
}

.desc-wrapper {
  text-align: left;
  margin-bottom: 15px;
  max-width: 95%;
}

.home-title-lined {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}

.truncText {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.agent-data-line {
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  max-width: 80%;
  padding-bottom: 14px !important;
}

.empty-field {
  height: 15px;
}

.lists {
  list-style-type: disc;
  display: inline-block;
  list-style-position:inside;
  column-count: 2;
  column-gap: 50px;
  padding-left: 0px;
  margin: 0;
}

.feat-item {
  color: #3d3d3d;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize !important;
  max-width: 260px;
  overflow-wrap: break-word;
  font-weight: 500;
}

.marker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.marker-container a {
  font-size: 15px;
  line-height: 23px;
  text-align: left !important;
  max-width: 80%;
  color: #3d3d3d;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.marker-container a:hover {
  color: #c4c4c4;
  font-weight: 500;
  text-decoration: underline;
}

.agent-data-pic {
  padding-right: 15px;
}

.address-names {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #3D3D3D;
  padding-right: 15px;
  min-width: 62px;
  text-decoration: none !important;
}

.agent-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #3D3D3D;
}

.left-block-part {
  padding-left: 22px !important;
  border-left: 1px solid rgba(61,61,61, 0.26);
}

@media (max-width: 600px) {
  .left-block-part {
    border-left: none;
    padding-left: 0 !important;
  }
}