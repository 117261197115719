.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pictile-container {
 display: flex;
 flex-direction: row;
 padding: 0 30px;
 flex-wrap: wrap;
}

.chart-container {
  display: flex; 
  flex-direction: row; 
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 30px;
  column-gap: 30px;
  row-gap: 30px;
}

.emptySpace {
  height: 100px;
}

@media (min-width: 1470px) {
  .main {
    width: 1440px;
    margin: auto;
  }

  .pictile-container {
    padding: 0;
  }
  .chart-container {
    margin: 60px 0;
  }
}

@media (max-width: 600px) {
  .chart-container {
    margin: 10px 0 0 0;
  }
  .emptySpace {
    height: 70px;
  }
}