

.emptyHeight {
 height: 80px;
}

@media (max-width: 768px) {
  .emptyHeight {
    height: 120px;
  }
}