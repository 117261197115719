.estate-tile {
background: #FFFFFF;
box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
margin: 0 30px;
}

.object-wrapper {
    margin: 0 30px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    flex-shrink: 1;
    flex-basis: auto;
    flex: 1 1;
}

.btn-left {
    height: 60px;
    width: 40px;
    position: fixed;
    z-index: 1000;
    border: 0;
    background: url('../images/btnLeft.svg') no-repeat center center;
    background-color: rgba(51,51,51,0.6);
    background-size: 100%;
    left: 0;
    top: 50%;
    border-radius: 0 10px 10px 0;
}

.btn-right {
    height: 60px;
    width: 40px;
    position: fixed;
    z-index: 1000;
    border: 0;
    background: url('../images/btnRight.svg') no-repeat center center;
    background-color: rgba(51,51,51,0.6);
    background-size: 100%;
    right: -1px;
    top: 50%;
    border-radius: 10px 0 0 10px;
}

.btn-left:hover, .btn-right:hover {
    background-color: #333333;
    transform: scale(1.2);
}

@media (max-width: 992px) {
    .btn-left, .btn-right {
        display: none;
    }
}

@media (min-width: 1470px) {
    .object-wrapper {
        max-width: 1440px;
        margin: auto;
    }
}

.skeleton-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}