.agent-list-wrapper {
  padding: 15px 10px 0 0 ;
  height: 170px;
  overflow-y: scroll;
}

.agent-list-no-wrap {
  padding-top: 16px;
  padding-bottom: 10px;
  padding-right: 5px !important;
  min-height: 160px;
}

.agent-list-item {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 3fr 2fr;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  align-items: center;
  color: #000000;
  padding: 16px 0 !important;
  align-items: flex-start;
  border-top: 1px solid #CCD0DB;
}

.agent-list-item span:first-child, .agent-list-item-no-date span:first-child {
  display: flex;
  /* padding-right: 20px; */
  text-align: left;
  max-width: 90%;
}

.agent-list-item span:nth-child(2), .agent-list-item-no-date span:nth-child(2) {
  font-weight: 500;
}

.agent-list-item span:nth-child(3), .agent-list-item-no-date span:nth-child(3) {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.qty-agents {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: normal;
  font-size: 11px;
  display: flex;
  align-items: center;
  color: #227BBB;
  padding-bottom: 10px;
}

.url-link {
  color: #3d3d3d;
  text-decoration: underline;
}

.url-link:hover {
  color: #c4c4c4;
  text-decoration: underline;
}

.heading-listed {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 16px !important;
  padding-top: 24px !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #3D3D3D;
}

.agent-list-wrap {
  overflow: hidden;
  padding-bottom: 20px !important;
}