.button {
  color: white !important;
  height: 44px;
  font-size: 12px;
  background-color: #333333;
  border-radius: 30px !important;
}

.button:focus, .button:hover {
  color: white !important;
  background-color: #3d3d3d !important;
}
