.chartWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #3d3d3d !important;
  background-color: rgba(	251,251,251, 0.9);
  border: 1px solid #CCD0DB;
  min-height: 261px;
  width: calc(50% - 20px);
  border-radius: 13px;
  padding-bottom: 33px;
}

.titleChart {
  padding: 20px 35px 35px 35px;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: left;
}

.colorDot {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  margin-right: 24px;
}

.dotWithLegend {
  padding: 10px 0 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dotTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: flex-end;
  color: #616161;
}

.dotQty {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  align-items: flex-end;
  color: #616161;
}

@media (max-width: 768px) {
  .chartWrap {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .legendWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 20px 0 0;
    margin-left: -7px;
  }
  .titleChart {
    font-size: 18px;
    padding-left: 17px;
  }
  .dotQty {
    font-size: 32px;
  }
  .chartWrap {
    border-color: transparent;
    border-radius: 0;
  }
}