
.table-header-journal {
  flex: 1;
  overflow: hidden;
  background-color: #3d3d3d;
  color: #ffffff;
  height: 48px;
  font-size: 12px;
  display: flex;
  align-items:center;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif !important;
  text-transform: capitalize !important;
  margin-right: 0 !important;
  font-weight: 400;
  text-align: left;
  padding-left: 16px;
}

.icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableLogs {
  display: grid;
  grid-template-columns: 170px 150px 200px 250px 250px;
  justify-content: start;
  align-content: center;
  height: 100%;
  align-items: center;
  justify-items: start;
}

.tableLogs:hover {
  background-color: rgba(61, 61, 61, 0.2) !important;
}

.logHeader {
  display: grid;
  height: 50px;
  color: #ffffff !important;
  justify-content: start;
  align-content: center;
  align-items: center;
  justify-items: start;
  font-size: 12px !important;
  grid-template-columns: 170px 150px 200px 250px 250px;
 }

 .logHeader div {
  padding-left: 15px;
  text-align: left;
}

