.closeButtonModal {
  background-color: transparent;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: -80px;
  z-index: 100;
  border: none;
  margin-right: 30px;
  cursor: pointer;
}

.closeButtonModal img {
  height: 30px;
  width: 30px;
}