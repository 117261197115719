.MuiDataGrid-cell {
  white-space: normal !important;
}

.MuiTypography-root {
  font-size: 12px !important;
  font-family: 'Montserrat', sans-serif !important;
}

.MuiButton-root {
  font-family: 'Montserrat', sans-serif !important;
  border: 1px solid rgba(61, 61, 61, 0.3) !important;
}

.Container {
  margin-right: 0;
  padding-right: 0;
  border: transparent !important;
}

li {
  font-size: 10px;
}

ul {
  padding-left: 10px;
}

.link {
  text-decoration: none;
  color: #3d3d3d;
}

.popover {
  padding: 10px;
}

.wrapper {
  display: flex;
  flex-direction: column !important;
  flex-grow: 3;
  flex-shrink: 1;
  flex-basis: auto;
  flex: 1;
  padding-top: 80px;
  padding-left: 30px;
}

.table-wrapper {
width: 100%;
display: flex;
flex-grow: 3;
flex-shrink: 1;
flex-basis: auto;
flex: 1;
flex-direction: column !important;
}

.total {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.07em;
  color: #3D3D3D;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 25px;
  height: 15px;
}

.fullscreen .table-pics {
  width: 80%;
  height: 80% !important;
  min-height: 500px;
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
  object-position: center center;
}

.fullscreen .table-pics img {
border: 4px solid transparent !important;
object-position: center center;
}

/* .table-slider div div div button {
  padding: 0 !important;
} */

.table-slider div div div button svg{
  height: 20px !important;
  width: 20px !important;
}