.transparent-button-filter {
  background-color: transparent;
  border: none;
  padding: 0;
  min-width: 140px !important;
  min-height: 40px !important;
  margin: 10px 10px 0 0!important;
  border-radius: 30px !important;
}

.transparent-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.buttonSet {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  width: 100% !important;
}

.Mui-checked {
  color: #3d3d3d !important;
}

.button-cover {
  margin-right: 30px;
  background-color: transparent !important;
}

.filter-span {
  padding-right: 9px;
}

.range-wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.chipWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 500px;
  padding-left: 0;
}

.chipWrap li {
  padding-bottom: 10px;
  padding-left: 0 !important;
  padding-right: 10px;
}

@media (max-width: 450px) {
  .transparent-button-filter {
    width: 45%;
  }
  .buttonSet {
    justify-content: space-between !important;
  }
}

@media (max-width: 600px) {
  .filter-span {
    display: none;
  }
}

@media (min-width: 1470px) {
  .button-cover {
    margin-right: 0;
  }
}

.filters-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #7E7CCF;
  width: 100%;
  padding: 24px 0 24px 16px;
  margin: 0;
}

.button-container {
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.switch-label {
  margin-left: 0 !important;
}

.switch-label span:last-child {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.07em;
  color: #3D3D3D;
  padding-right: 10px;
}
