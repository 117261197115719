.activeBtn {
  padding: 8px 12px;
  background-color: #F0FFF8;
  color: #18AB56;
  text-transform: capitalize;
  border-radius: 8px;
  width: fit-content;
}

.removedBtn {
  padding: 8px 12px;
  background-color: #DCDBDA;
  color: #9E9A99;
  text-transform: capitalize;
  border-radius: 8px;
  width: fit-content;
}

.redBtn {
  padding: 8px 12px;
  background-color: #FDCECE;
  color: #EB5757;;
  text-transform: capitalize;
  border-radius: 8px;
  width: fit-content;
}