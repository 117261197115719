.roundIconButton {
  height: 32px !important;
  width: 32px !important;
  background-repeat: no-repeat;
  z-index: 100;
  background-color: transparent !important;
  border: none;
  margin-right: 11px;
  margin-top: 2px !important;
}

#exit {
  cursor: pointer;
  background-image: url('../../../images/logout.svg');
  margin-left: 41px;
  margin-bottom: 3.5px;
}

#iconGear {
  background-image: url('../../../images/gear.svg');
  cursor: pointer;
}

#iconGear:hover {
  background-image: url('../../../images/sett.svg');
}

#iconGearBlack {
  background-image: url('../../../images/gearBlack.svg');
  cursor: pointer;
}


#iconJournal {
  cursor: pointer;
  background-image: url('../../../images/journal.svg');
}

#iconJournal:hover {
  background-image: url('../../../images/jrn.svg');
}

#iconJournalBlack {
  background-image: url('../../../images/journalBl.svg');
  cursor: pointer;
}

.burger-button {
  border: none;
  background-color: transparent;
  padding-top: 40px;
  padding-left: 24.5px;
  display: flex;
  flex-direction: flex-start;
  cursor: pointer;
  margin-left: 0 !important;
}


a {
  color: #ffffff;
  text-decoration: none;
}

.appBar {
  background-color: #f6f6f4 !important;
}

.sideMenu {
  padding-left: 25px !important;
  min-height: 60px !important;
}

.logo {
  margin-left: 20px;
}

.logo-cont {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}

.textWithLogo {
  color: #ffffff !important;
  padding-left: 0 !important;
  display: flex;
  padding-top: 0.5px;
  justify-content: flex-start;
}

.textWithLogo span {
  font-size: 14px !important;
}

.pageHeader {
  color: #3d3d3d;
  background-color: #ffffff !important;
  font-size: 16px !important;
}

.small-btn-cont {
  height: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
#exit {
  margin-left: 0;
}

}



@media (max-width: 600px) {
  .appBar {
    box-shadow: none !important;
    background-color: rgba(255,255,255, 0.85) !important;
    backdrop-filter: blur(3px);
  }

  .page-heading {
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.019em;
    color: #1E1E1E;
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: -40px;
    justify-content: center;
  }
  .btn-with-name {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1470px) {
  .sideMenu {
    width: 1440px;
    padding-left: 0 !important;
    margin: auto;
  }
}

@media (min-width: 600px) {
 .page-heading {
  display: none;
 }
}