.status-label {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  top: 23px;
  padding: 6px !important;
  z-index: 500;
  border-radius: 30px 0 0 30px;
  font-weight: bold;
  font-size: 13px;
  color: #3d3d3d;
  letter-spacing: 0.07em;
}

.status-label-objpage {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  bottom: 23px;
  padding: 6px !important;
  z-index: 500;
  border-radius: 30px 0 0 30px;
  font-weight: bold;
  font-size: 13px;
  color: #3d3d3d;
  letter-spacing: 0.07em;
}

.status-label img, .status-label-objpage img {
  margin-right: 6px;
}