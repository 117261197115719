.closeButton {
  background-color: transparent;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 100;
  border: none;
  margin-right: 30px;
  cursor: pointer;
}

.closeButton img {
  height: 30px;
  width: 30px;
}

.ImageModal {
  padding-bottom: 20px;
}