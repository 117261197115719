.PageMobile {
  position: relative;
}

.RowLine {
  color: #3d3d3d;
  opacity: 0.5;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.name-container {
  padding-top: 40px !important;
  font-size: 18px;
}

.name-container div:first-child {
  color: #3d3d3d !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.TransparentButton {
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.ParameterHeading {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
  color: #979797;
  padding-top: 20px !important;
  padding-bottom: 15px !important;
  text-align: left;
}

.ParameterHeadingSum {
  padding-top: 30px;
}

.Sum {
  font-size: 28px;
  line-height: 140%;
  font-weight: bold;
  color: #3d3d3d;
  min-width: 178px;
}

.SumOwnLine {
  padding-top: 18px !important;
  display: flex;
  flex-direction: row;
}


@media (max-width: 768px) {
  .PageMobile {
  margin: 0px;
  padding-top: 0;
}
}

.address-line {
  padding-top: 20px;
  padding-right: 40px;
  display: flex;
  justify-content: flex-start;
  line-height: 32px;
  font-size: 14px;
  color: #333333;
  text-align: left;
}

.ParameterText {
  padding-top: 20px;
  padding-right: 40px;
  display: flex;
  color: #3d3d3d !important;
}

.X10 {
  padding-right: 10px;
  font-size: 15px;
  align-items: center;
  display: flex;
}

.IconPic {
  padding-right: 15px !important;
}

.one-feature {
  display: flex;
  flex-direction: row;
  margin-right: 31px;
}

.attr-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px !important;
}

.Icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 3.5px;
}

.Icons button {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #3d3d3d;
  padding-right: 15px;
}

.IconButton {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.IconButtonLast {
  position: absolute;
  border: none;
  right: 20px;
  font-size: 12px;
  background-color: white;
  background-image: url('../../../images/arrows.svg');
  top: -122px;
  z-index: 1000;
  margin: 0;
  padding: 8px 34px 8px 14px;
  border-radius: 30px;
  color: #7E7CCF;
  font-weight: 600;
  text-decoration: underline;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 90% 51%;
}

.IconButton2 {
  background-color: transparent;
  border: none;
  padding-right: 55px !important;
  margin-bottom: 6px;
}

.home-title-lined {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}

.feat-container {
  display: flex;
  flex-direction: column;
}

.type-estate {
  padding-top: 10px !important;
  color: #3d3d3d;
  opacity: 0.7;
}

.IconButton3 {
  background-color: transparent;
  border: none;
  padding-right: 55px !important;
  margin-bottom: 5px;
  padding: 0;
  margin: 0;
  height: 47px;
  width: 47px;
  border: none;
  background-image: url('../../../images/heartL.svg');
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 18px;
}

.IconButton3:hover {
  background-color: transparent;
  border: none;
  padding-right: 55px !important;
  margin-bottom: 5px;
  padding: 0;
  margin: 0;
  height: 47px;
  width: 47px;
  border: none;
  background-image: url('../../../images/heartL.svg');
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 18px;
}

.IconButton4 {
  background-color: transparent;
  border: none;
  padding-right: 55px !important;
  margin-bottom: 5px;
  padding: 0;
  margin: 0;
  height: 47px;
  width: 47px;
  border: none;
  background-image: url('../../../images/heartW.svg');
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 18px;
}

.IconButton4:hover {
  background-color: transparent;
  border: none;
  padding-right: 55px !important;
  margin-bottom: 5px;
  padding: 0;
  margin: 0;
  height: 47px;
  width: 47px;
  border: none;
  background-image: url('../../../images/heartL.svg');
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 18px;
}