.single-home-tile {
  background: #FFFFFF;
  border-radius: 13px;
  border: 1px solid #CCD0DB;
  box-shadow: 0px 4px 20px rgba(199, 199, 199, 0.25);
  height: 345.61px;
  width: 100%;
}

.home-title {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #000000;
}

.home-title h2 {
  margin-bottom: 12px;
  font-size: 14px;
  text-align: left;
  height: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.price-line {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #3D3D3D;
}

.feats {
  display: flex;
  flex-direction: row;
 padding-bottom: 15px;
  padding-top: 10px;
}

.one-feat {
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #000000;
}

.grey-hart {
  position: absolute;
  background-color: transparent;
  padding: 0;
  margin: 0;
  height: 18px;
  width: 19px;
  border: none;
  right: 15px;
  bottom: 15px;  
  background-image: url('../../images/hartDark.svg');
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.grey-hart:hover {
  background-image: url('../../images/hartBlack.svg');
}

.white-hart {
  position: absolute;
  background-color: transparent;
  padding: 0;
  margin: 0;
  height: 18px;
  width: 19px;
  border: none;
  right: 15px;
  bottom: 15px;  
  background-image: url('../../images/heartG.svg');
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.white-hart:hover {
  background-image: url('../../images/hartBlack.svg');
}


.object-pics {
  width: 100%;
  overflow: hidden;
  height: 200px;
}

.object-pics img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
}

.fullscreen .object-pics {
  width: 80%;
  height: 80% !important;
  min-height: 500px;
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
  object-position: center center;
}

.fullscreen .object-pics img {
border: 4px solid transparent !important;
object-position: center center;
}


.black-button {
  background-color: #3d3d3d;
  color: white;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 39px;
  width: 26px;
  background-color: rgba(255, 255, 255, 0.5);
}

.image-gallery-left-nav, .image-gallery-right-nav  {
  padding: 0;
}

.tab-panel {
  position: relative;
}

.tab-panel div {
  padding: 0;
}

.no-image {
 height: 200px;
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}

.link-div {
  cursor: pointer;
}

.image-wrapper img {
  max-height: 250px;
}

